<template>
  <v-container class="fill-height ma-0 pa-0" fluid>
    <v-row class="ma-0 pa-0 text-center" v-if="loading">
      <v-col><v-progress-circular indeterminate color="primary" /></v-col>
    </v-row>
    <v-row align="center" class="ma-0 pa-0" v-else-if="restaurantList.length">
      <v-col cols="12" xs="12" sm="12" md="4" lg="4" xl="2" v-for="restaurant in restaurantList" :key="restaurant.id">
        <restaurant-card :restaurant="restaurant" />
      </v-col>
    </v-row>
    <v-row align="center text-center" v-else class="ma-0 pa-0">
      <v-col>
        <span class="font-weight-thin">No restaurant to display.</span>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import firebase from '@/plugins/firebase'
import RestaurantCard from './RestaurantCard'

export default {
  name: 'RestaurantList',

  components: {
    'restaurant-card': RestaurantCard
  },

  data () {
    return ({
      loading: false,
      restaurantList: []
    })
  },

  methods: {
    getRestaurantList () {
      return new Promise((resolve, reject) => {
        var resolveValue = []
        var restaurantCollection = firebase.firestore().collection('/restaurants')
          .where('published', '==', true)
          .where('toBeDeleted', '==', false)
          .orderBy('rating', 'desc')
          .orderBy('dateAdded', 'desc')
          .get()

        restaurantCollection
          .then(collectionSnapshot => {
            collectionSnapshot.docs.forEach(doc => {
              resolveValue.push(Object.assign({ id: doc.id }, doc.data()))
            })
            resolve(resolveValue)
          })
          .catch(e => { reject(e) })
      })
    }
  },

  async mounted () {
    this.$data.loading = true

    try {
      this.$data.restaurantList = await this.getRestaurantList()
    } catch (e) {
      this.$store.commit('toast/setError', 'Cannot get restaurant list')
      console.error(e)
    }

    this.$data.loading = false
  }
}
</script>
