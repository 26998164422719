<template>
  <v-card :to="`/restaurants/${restaurant.id}/${restaurant.url}`">
    <v-img aspect-ratio="1" :src="restaurant.primaryPhoto">
      <template v-slot:placeholder>
        <v-row
          class="fill-height ma-0"
          align="center"
          justify="center"
        >
          <v-progress-circular indeterminate color="primary"></v-progress-circular>
        </v-row>
      </template>
    </v-img>
    <v-card-title>{{ restaurant.name }}</v-card-title>
    <v-card-text>
      <span class="font-weight-thin" v-if="reviews === 0">
        No reviews yet
      </span>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  name: 'RestaurantCard',

  props: {
    restaurant: {
      type: Object,
      required: true
    }
  },

  data () {
    return ({
      stars: null,
      reviews: 0 // Change this later
    })
  }
}
</script>
